import { Controller } from "stimulus";
import { tns } from "tiny-slider/src/tiny-slider";

class CarouselClientsController extends Controller {

  initialize() {
    window.client_slider = window.client_slider ? window.client_slider : []
  }

  connect() {
    let numberOfItems = this.element.getAttribute("data-carousel-item-count")
    let gutter = this.element.getAttribute("data-carousel-gutter")

    /**
     * Responsive block acts the same as a min-width media query
     * The base values are for mobile and you scale up to desktop
     * 640px = 40rem
     */

    window.client_slider.push(tns({
        container: this.element.querySelector(".carousel-wrapper"),
        items: 1,
        autoplay: false,
        nav: false,
        controls: true,
        gutter: gutter ? gutter : 0,
        autoplayButtonOutput: false,
        controlsPosition: "bottom",
        controlsText: ["<span class='visuallyhidden'>previous slide</span><svg class='icon--large'><use xlink:href='#icon-arrow-left' /></svg>", "<span class='visuallyhidden'>next slide</span><svg class='icon--large'><use xlink:href='#icon-arrow-right' /></svg>"],
        responsive: {
          640: {
            items: numberOfItems ? numberOfItems : 1,
          }
        },
      })
    );
  }

  disconnect() {
    window.client_slider.forEach(function (item) {
      item.destroy();
    })
    window.client_slider = [];
  }
}

export default CarouselClientsController;
