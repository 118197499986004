import { Controller } from "stimulus"

class FocusController extends Controller {
  static targets = ["input"];

  requestFocus() {
    this.inputTarget.focus();
  }

  clearFocus(){
    this.inputTarget.blur()
  }

}

export default FocusController;
