import { Controller } from "stimulus";

export default class ModalController extends Controller {

  connect() {
    try {
      MicroModal.init({
        awaitCloseAnimation: true,
      });
    } catch (e) {
      console.log("micromodal error: ", e);
    }
  }

  openModal(e) {
    e.preventDefault();
    let target = e.target.closest("[data-modal-id]");
    MicroModal.show(target.getAttribute("data-modal-id"), { disableFocus: true });

    // Add any custom configurations to a modal after opening
    switch(target.getAttribute("data-modal-id")){
      case "youtube-modal":
        // player object can be found in views/shared/_youtube_modal.html.erb
        player.loadVideoById(`${target.getAttribute("data-video-path")}`);
        break;
    }
  }

  disconnect() {
    try {
      MicroModal.close();
    } catch (e) {
      // ignore errors, modal may not be open
    }
  }
}
