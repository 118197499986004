import { Controller } from "stimulus";
import { debounce } from "debounce";
import Rails from '@rails/ujs';
import * as L from "leaflet";

//
// Purpose:
//
//   Controls any javascript behaviour around project location/address
//   search and selection
//
export default class ProjectLocationController extends Controller {
  static targets = [
    "addressQuery",
    "addressOptions",
    "latitude",
    "longitude",
    "fullAddress",
    "suburb",
    "geoServiceRefId",
    "selectedAddress"
  ];

  connect() {
    this.map = L.map('map').setView([-34.9285, 138.6007], 9);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoia2F0YWx5c3QiLCJhIjoiY2tiYnJxMHhwMDNtZzJ4bjBqMHdqOXc1dyJ9.27q0hXTUDT56eMy-Rsu3mQ'
    }).addTo(this.map);

    this.map.on('contextmenu', this.placeMarkerFromMap);

    this.marker = null;
  }

  search() {
    const query = this.addressQueryTarget.value;
    findAddressesDebounced(query, (response) => {
      this.addressOptionsTarget.innerHTML = response.html;
    })
  }

  selectAddress(event) {
    const data = event.currentTarget.dataset;
    // set display adress
    this.selectedAddressTarget.innerHTML = data.fullAddress;
    // set fields
    this.latitudeTarget.value = data.latitude;
    this.longitudeTarget.value = data.longitude;
    this.fullAddressTarget.value = data.fullAddress;
    this.suburbTarget.value = data.suburb;
    this.geoServiceRefIdTarget.value = data.geoServiceRefId;
    // clear the options and search
    this.addressOptionsTarget.innerHTML = "";
    this.addressQueryTarget.value = null;
    // set and zoom to map marker
    this.replaceMarker(data.latitude, data.longitude);
  }

  placeMarkerFromMap = (e) => {
    const latlng = e.latlng;
    this.replaceMarker(latlng.lat, latlng.lng, false);
    this.latitudeTarget.value = latlng.lat;
    this.longitudeTarget.value = latlng.lng;
    // clear address values from search
    this.selectedAddressTarget.innerHTML = null;
    this.fullAddressTarget.value = null;
    this.suburbTarget.value = null;
    this.geoServiceRefIdTarget.value = null;
  };

  updateCoordinatesFromInput = () => {
    this.replaceMarker(this.latitudeTarget.value, this.longitudeTarget.value)
  };

  replaceMarker = (latitude, longitude, zoom = true) => {
    if(latitude && longitude){
      if(this.marker){
        this.marker.remove();
      }
      this.marker = L.marker([latitude, longitude], { draggable: true })
        .on('move', this.onMarkerMove)
        .addTo(this.map);

      if(zoom){
        this.zoomToMarker(this.marker);
      }
    }
  };

  onMarkerMove = (e) => {
    this.latitudeTarget.value = e.latlng.lat;
    this.longitudeTarget.value = e.latlng.lng;
  };

  zoomToMarker = (marker) => {
    this.map.fitBounds(
      L.latLngBounds(
        [marker.getLatLng()]
      ),
      { maxZoom: 11 }
    );
  };

}

const findAddressesDebounced = debounce(findAddresses, 250);

async function findAddresses(query, callback) {
  if(query.length < 3) {
    return callback({ html: "" });
  }

  Rails.ajax({
    url: "/address_lookup",
    type: "GET",
    data: "query=" + query,
    success: (response) => callback(response),
    error: (data) => {
      alert("Something went wrong finding addresses");
    }
  });
}
