import { Controller } from "stimulus";

export default class GlobalOfficePanelController extends Controller {
  static targets = ["link"];

  selectOffice(e) {
    const url = e.target.value;
    this.linkTarget.href = url
  }
}
