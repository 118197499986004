import {Controller} from "stimulus"

class SlideMenuController extends Controller {
    static targets = ["menu"]

    open(event) {
        event.preventDefault();
        // only connectPlus will have the attribute
        if (!document.body.hasAttribute("data-user-state") || document.body.getAttribute("data-user-state") === "authenticated") {
            this.menuTarget.classList.add("slide-menu--open");
            this.menuTarget.removeAttribute("inert");
            this.menuTarget.removeAttribute("aria-hidden")
        }
    }

    close(event) {
        event.preventDefault();
        this.menuTarget.classList.remove("slide-menu--open");
        this.menuTarget.setAttribute("inert", true)
        this.menuTarget.setAttribute("aria-hidden", true)
    }
}

export default SlideMenuController;
