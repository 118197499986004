import { Controller } from "stimulus";
import { Transition } from "./utils/transition";

class FeaturedItemController extends Controller {
  static targets = ["content"]
  connect(){
    setTimeout(() => show(this.element),this.element.getAttribute("data-delay"))
  }

}

function show(element){
  element.removeAttribute("data-hide")
}
export default FeaturedItemController;
