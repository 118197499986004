import { Controller } from "stimulus";

class UserPreferencesController extends Controller {
  static targets = ["customMarket", "customExistingMarket", "customSector", "userTypes", "allSectors", "allMarkets", "sectors", "markets", "personalisation", "exporter", "readinessType"];

  connect() {
    if (this.hasAllSectorsTarget) {
      syncAllCheckbox(this.allSectorsTarget, this.sectorsTarget)
    }

    if (this.hasAllMarketsTarget) {
      syncAllCheckbox(this.allMarketsTarget, this.marketsTarget)
    }

    if (this.exporterTarget.checked) {
      this.readinessTypeTarget.classList.remove("hidden");
    }
  }

  toggleMarkets(e) {
    this.customMarketTarget.toggleAttribute("data-hide", !isChecked(e.target, "Other"));
    syncAllCheckbox(this.allMarketsTarget, this.marketsTarget)
  }

  toggleExistingMarkets(e) {
    this.customExistingMarketTarget.toggleAttribute("data-hide", !isChecked(e.target, "Other"));
  }

  toggleSectors(e) {
    this.customSectorTarget.toggleAttribute("data-hide", !isChecked(e.target, "Other"));
    syncAllCheckbox(this.allSectorsTarget, this.sectorsTarget)
  }

  toggleAllSectors(e) {
    toggleOptions(this.sectorsTarget, e.target.checked);
  }

  toggleAllMarkets(e) {
    toggleOptions(this.marketsTarget, e.target.checked);
  }
  
  openPersonalisation(e) {
    this.personalisationTarget.classList.remove("hidden");

    if (this.exporterTarget.checked) {
      this.readinessTypeTarget.classList.remove("hidden");
    }
  }

  closePersonalisation(e) {
    this.personalisationTarget.classList.add("hidden");
  }

  toggleReadinessType(e) {
    if (e.target.checked) {
      this.readinessTypeTarget.classList.remove("hidden");
    } else {
      this.readinessTypeTarget.classList.add("hidden");
    }
  }

}

function toggleOptions(multiselect, checked) {
  const selector = checked ? "[role=option]:not([selected])" : "[role=option][selected]";
  Array.from(multiselect.querySelectorAll(selector)).filter(option => option.innerText !== "Other").forEach(option => {
    option.click();
  })
}

function syncAllCheckbox(allCheckbox, multiselect) {
  const unselected = multiselect.querySelectorAll("[role=option]:not([selected])");
  if (Array.from(unselected).filter(option => option.innerText !== "Other").length > 0) {
    allCheckbox.checked = false
  } else {
    allCheckbox.checked = true
  }
}

function isChecked(input, optionText) {
  const selected = input.querySelectorAll("[role=option][selected]");
  return Array.from(selected).filter(option => option.innerText === optionText).length > 0;
}

export default UserPreferencesController;
