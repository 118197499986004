import { Controller } from "stimulus"

class RestrictedDataController extends Controller {
  static targets = ["investmentTypeValue", "investmentValue", "minInvestmentValue", "maxInvestmentValue"]

  connect() {
    this.toggle();
  }

  toggle() {
    const option = this.investmentTypeValueTargets.filter(e => e.checked)[0];

    if (option) {
      this.investmentValueTarget.classList.toggle("hidden", !(option.value === "specific_amount"));
      this.minInvestmentValueTarget.classList.toggle("hidden", !(option.value === "range_amount"));
      this.maxInvestmentValueTarget.classList.toggle("hidden", !(option.value === "range_amount"));
    }
  }

  resetValues() {
    this.investmentValueTarget.getElementsByTagName("input")[0].value = "";
    this.minInvestmentValueTarget.getElementsByTagName("input")[0].value = "";
    this.maxInvestmentValueTarget.getElementsByTagName("input")[0].value = "";
  }
}

export default RestrictedDataController;
