import ShowHideController from "./show_hide_controller";

class SearchController extends ShowHideController {
  static targets = ["toolbar"];

  toggle(event) {
    super.toggle(event)
    this.toolbarTarget.toggleAttribute("active");
  }

  hide(event) {
    super.hide(event)
    this.toolbarTarget.removeAttribute("active");
  }
}

export default SearchController;
