import { Controller } from "stimulus";

/**
 * A custom multi-select menu widget.
 */
export default class MultiSelectController extends Controller {
  static targets = ["result"];

  /**
   * Open the menu by setting the appropriate attribute.
   *
   * Cancels close if the menu was previously closing.
   */
  openMenu() {
    this.element.toggleAttribute("open", true);
    this.element.toggleAttribute("closing", false);
  }

  /**
   * Close the menu by unsetting the open attribute.
   *
   * The menu closes if it or its descendants lose focus, so we use a two
   * step set-then-check approach to allow the user to switch focus.
   */
  closeMenu() {
    // Blur occurs before focus, so when a blur occurs we want to prepare to close
    // the menu but wait until the new focus event has happened (using setTimeout)
    // so that if the new focus is inside this element it can cancel the close.
    this.element.toggleAttribute("closing", true);
    setTimeout(() => {
      if (this.element.hasAttribute("closing")) {
        this.element.toggleAttribute("open", false);
        this.element.toggleAttribute("closing", false);
      }
    });
  }

  /**
   * Toggle the menu.
   *
   * @return true of the menu is open after toggle.
   */
  toggleMenu(force = undefined) {
    const open = force !== undefined ? force : this.element.hasAttribute("open");
    open ? this.closeMenu() : this.openMenu();
    return open;
  }

  /**
   * Toggle whether an option is selected or not. A selected option should have
   * a corresponding hidden input that will submit its value to the form.
   */
  toggleOption(option) {
    const selected = option.toggleAttribute("selected");

    if (selected) {
      const template = this.element.querySelector("input");
      const input = template.cloneNode();
      input.value = option.getAttribute("value");
      template.insertAdjacentElement("afterEnd", input);
    } else {
      this.element.querySelector(`input[value="${option.getAttribute("value")}"]`).remove();
    }

    this.updateResult();

    this.element.dispatchEvent(new Event("change", { bubbles: true }));
  }

  /**
   * On change, update the 'result' field which summarises the selection for the
   * user. Should be consistent with the server's implementation.
   */
  updateResult() {
    const selected = this.element.querySelectorAll(`[role=option][selected]`);
    switch (selected.length) {
      case 0:
        this.resultTarget.innerText = "Please select";
        this.resultTarget.classList.toggle("placeholder", true);
        break;
      case 1:
        this.resultTarget.innerText = selected[0].innerText;
        this.resultTarget.classList.toggle("placeholder", false);
        break;
      default:
        this.resultTarget.innerText = selected[0].innerText + ` (+${selected.length - 1} more)`;
        this.resultTarget.classList.toggle("placeholder", false);
        break;
    }
  }

  /**
   * Handle browser focus events on the input and its children.
   * If the input gains focus while closing then cancel the close.
   */
  focus(e) {
    if (this.element.hasAttribute("closing")) this.openMenu();
  }

  /**
   * Handle browser blur events on the input and its children.
   * The menu will close if the next focus event is not within this input.
   */
  blur(e) {
    this.closeMenu();
  }

  /**
   * Handles click events anywhere in the widget's dom, dispatches on target.
   *
   * Click on the result field opens the menu, while click on an option toggles the option.
   */
  click(e) {
    if (this.resultTarget.contains(e.target)) {
      this.toggleMenu();
    } else {
      const option = e.target.closest("[role='option']");

      if (option) {
        e.preventDefault();
        this.toggleOption(option);
      }
    }
  }

  /**
   * Handles key down events anywhere within the widget's dom.
   */
  keydown(e) {
    let option, open;
    switch (e.key) {
      case "Enter":
        open = this.toggleMenu();
        if (!open) {
          this.resultTarget.focus();
        }
        break;
      case "Down":
      case "ArrowDown":
        option = e.target.closest("option");
        if (option && option.nextElementSibling) {
          option.nextElementSibling.focus();
        } else if (!this.element.hasAttribute("open")) {
          this.openMenu();
          this.element.querySelector("option").focus();
        } else if (this.resultTarget.contains(e.target)) {
          this.element.querySelector("option").focus();
        } else {
          return; // we have not handled this key press
        }
        break;
      case "Up":
      case "ArrowUp":
        option = e.target.closest("option");
        if (option && option.previousElementSibling) {
          option.previousElementSibling.focus();
        } else if (this.element.hasAttribute("open")) {
          this.closeMenu();
          this.resultTarget.focus();
        } else {
          return; // we have not handled this key press
        }
        break;
      case " ":
        option = e.target.closest("option");
        if (option) {
          this.toggleOption(option);
        } else {
          return; // we have not handled this key press
        }
        break;
      default:
        return; // we have not handled this key press
    }

    e.preventDefault();
  }
}
