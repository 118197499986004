import {Controller} from "stimulus";
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import Choices from 'choices.js';

class FilterController extends Controller {
  static targets = ["menu", "choices"];

  connect() {
    // open the filter menu if the url anchor is `#filter`
    this.menuTarget.classList.toggle("show", !!location.hash.match(/filter/));

    this.choicesTargets.forEach(e => {
      new Choices(e, {allowHTML: true, removeItems: true, removeItemButton: true})

      e.addEventListener("change", this.update.bind(this));
    });

  }

  toggle(event) {
    // based on `ShowHideController`
    event.preventDefault();
    this.menuTarget.classList.toggle("show");
  }

  /**
   * Submit the form whenever a change occurs.
   */
  update() {
    Rails.fire(this.element, "submit");
  }

  /**
   * UJS has hooks to render HTML responses to non-get requests, but it doesn't
   * usually render GET requests. This method intercepts UJS Ajax submit and
   * uses Turbolinks to retrieve the rendered GET parameters instead.
   * */
  submit(event) {
    const detail = event.detail, xhr = detail[0], options = detail[1];
    event.preventDefault();
    let url = options.url;

    // if filter is open, append #filter anchor to the url
    if (this.menuTarget.classList.contains("show")) {
      url += "#filter"
    }

    Turbolinks.visit(url);
  }
}

export default FilterController;
