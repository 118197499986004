import { Controller } from "stimulus";
import { tns } from "tiny-slider/src/tiny-slider";

class CarouselController extends Controller {

  initialize() {
    tns({
      container: this.element,
      items: 1,
      autoplay: false,
      controls: true,
      nav: true,
      autoHeight: true,
      autoplayButtonOutput: false,
      navPosition: "bottom",
      controlsPosition: "bottom",
      controlsText: ["<span class='visuallyhidden'>previous slide</span><svg class='icon--large'><use xlink:href='#icon-arrow-left' /></svg>", "<span class='visuallyhidden'>next slide</span><svg class='icon--large'><use xlink:href='#icon-arrow-right' /></svg>"],
      responsive: {
        764: {
          autoplay: true,
          mouseDrag: true,
        },
      },
    });
  }
}

export default CarouselController;
