import { Controller } from "stimulus";

export default class BannerController extends Controller {

  connect() {
    if(window.location.search.includes("share_token")){
      navigator.clipboard.writeText(window.location).then(function() {
      //  Ignore response
      });
    }
  }
}
