import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Turbolinks from 'turbolinks';

export default class TurbolinksFormController extends Controller {
  initialize() {
    this.send = this.send.bind(this);
  }

  connect() {
    this.element.addEventListener("ajax:beforeSend", this.send);
  }

  disconnect() {
    this.element.removeEventListener("ajax:beforeSend", this.send);
  }

  /**
   * Respond to an event (for example, a change) by submitting the form.
   * @param event
   */
  submit(event) {
    Rails.fire(this.element, "submit");
  }

  /**
   * UJS has hooks to render HTML responses to non-get requests, but it doesn't
   * usually render GET requests. This method intercepts UJS Ajax submit and
   * uses Turbolinks to retrieve the rendered GET parameters instead.
   * */
  send(event) {
    const detail = event.detail, xhr = detail[0], options = detail[1];
    event.preventDefault();
    const url = options.url;

    Turbolinks.visit(url);
  }
}
