import { Controller } from "stimulus";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

export default class extends Controller {
  static targets = ["trigger", "content"]

  initialize() {
    this.initPopup();

    this.contentTarget.style.display = "none";
  }

  initPopup() {
    this.popup = tippy(this.triggerTarget, {
      content: this.contentTarget.innerHTML,
      allowHTML: true,
      placement: 'bottom',
      theme: 'light-border'
    });
  }


}
