import { Controller } from "stimulus";
import { tns } from "tiny-slider/src/tiny-slider";

class CarouselProjectsController extends Controller {
  
  initialize() {
    tns({
      container: this.element,
      items: 2,
      autoplay: false,
      nav: false,
      controls: true,
      gutter: 8,
      autoplayButtonOutput: false,
      controlsPosition: "bottom",
      controlsText: ["<", ">"],
    });
  }
}

export default CarouselProjectsController;
