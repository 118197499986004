import {Controller} from "stimulus"

class ProjectSetupController extends Controller {
  static targets = ["projectOrganisationTemplate", "governmentAgencyTemplate", "addProjectOwner", "ownerType", "projectOwner"]

  addProjectOrganisation(event) {
    event.preventDefault();

    let content = this.projectOrganisationTemplateTarget.innerHTML.replace(/PROJECT_ORGANISATION_RECORD/g, new Date().getTime());
    this.addProjectOwnerTarget.insertAdjacentHTML("afterend", content);
  }

  addGovernmentAgency(event) {
    event.preventDefault();

    let content = this.governmentAgencyTemplateTarget.innerHTML.replace(/GOVERNMENT_AGENCY_RECORD/g, new Date().getTime());
    this.addProjectOwnerTarget.insertAdjacentHTML("afterend", content);
  }

  removeAssociation(event) {
    event.preventDefault();

    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.classList.add("hidden");
  }
}

export default ProjectSetupController;
