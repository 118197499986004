import {Controller} from "stimulus";
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

export default class extends Controller {

  update(event) {
    let url = new URL(window.location.href)
    let search_params = url.searchParams;

    if (event.currentTarget.value == "list") {
      search_params.set(event.currentTarget.name, event.currentTarget.value);
    } else {
      search_params.delete(event.currentTarget.name);
    }

    Turbolinks.visit(url);
  }
}

