import {Controller} from "stimulus"
import * as L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
import MarkerIcon from '../../stylesheets/images/marker-icon.png';
import MarkerIconx2 from '../../stylesheets/images/marker-icon-2x.png';
import MarkerShadow from '../../stylesheets/images/marker-shadow.png';
import Rails from "@rails/ujs";
import ProjectFeatureLoader from '../utility/map/project_feature_loader';

L.Icon.Default.mergeOptions({
  iconUrl:       MarkerIcon,
  iconRetinaUrl: MarkerIconx2,
  shadowUrl:     MarkerShadow,
});

//
// MAP FOR PROJECTS INDEX PAGE
//

class ProjectsMapController extends Controller {
  static targets = [];

  connect() {
    const startLatitude = this.data.get("start-latitude");
    const startLongitude = this.data.get("start-longitude");

    this.map = L.map('projects-map').setView([startLatitude, startLongitude], 7);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoia2F0YWx5c3QiLCJhIjoiY2tiYnJxMHhwMDNtZzJ4bjBqMHdqOXc1dyJ9.27q0hXTUDT56eMy-Rsu3mQ'
    }).addTo(this.map);

    this.loadMarkers()
  }

  loadMarkers = () => {
    const url = this.data.get("url");
    Rails.ajax({
      url: url,
      type: "GET",
      success: this.onProjectsLoaded,
      error: this.onErrorLoadingProjects,
    });
  };

  onProjectsLoaded = (response) => {
    new ProjectFeatureLoader({projects: response.projects}).load(this.map)
  };

  onErrorLoadingProjects = (response) => {
    alert("Something went wrong finding map data");
  };
}

export default ProjectsMapController;
