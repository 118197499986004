import {Controller} from "stimulus"

class PopupMenuController extends Controller {
    static targets = ["menu"]

    toggleTopMenu(event) {
        event.preventDefault()
        this.menuTarget.toggleAttribute("data-active")
    }

    closeTopMenu() {
        this.menuTarget.removeAttribute("data-active")
    }
}

export default PopupMenuController;
